<template>
  <div>
    <div class="fixed pin-l pin-t pin-b bg-blue-darker p-4">
      <router-link tag="h1"
                   class="text-white text-sm font-bold uppercase tracking-wide"
                   :to="{name: 'home'}">Security Scanner</router-link>
    </div>
    <div class="container">
    <div class="layout">
      <div class="container">
        <div class="flex">
          <scan-result class="w-1/3 mr-10"
                       colour="green"
                       percent="95"
                       status="Very Good"
                       human-time="2 days ago"
                       detailed-time="20/11/19"
                       name="#scan001"
                       url="https://google.com" />
          <scan-result class="w-1/3 mr-10"
                       colour="yellow"
                       percent="60"
                       status="Average"
                       human-time="2 days ago"
                       detailed-time="20/11/19"
                       name="#scan002"
                       url="https://google.com" />
          <scan-result class="w-1/3 mr-10"
                       colour="red"
                       percent="20"
                       status="Very Poor"
                       human-time="2 days ago"
                       detailed-time="20/11/19"
                       name="#scan003"
                       url="https://google.com" />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import ScanResult from '../scans/ScanResult';
export default {
  components: { ScanResult },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
  },
};
</script>
